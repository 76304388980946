// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export type DotsProps = {
  color?: string;
  arabic?: boolean;
};
export const Dots = styled.div<DotsProps>(
  ({ color, arabic }) => `
    width: 28px;
    height: 28px;
    background: #F7F8F9;
    border: 2px solid ${color};
    border-radius: 14px;
    transform: rotate(90deg);
  @media (max-width: 992px) {
    width: 14px;
    height: 14px;
    border-radius: 10px;
    }
        `
);

export type TextProps = {
  arabic?: boolean;
};
export const Text = styled.div<TextProps>(
  ({ arabic }) => `
  font-family: ${arabic ? "Cairo" : "Dm Sans"};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  color: #676767;
  direction: ${arabic ? "rtl" : "ltr"};
  text-align: ${arabic ? "right" : "left"};

`
);

export type InputComponentProps = {
  arabic?: boolean;
};
export const InputComponent = styled.input<InputComponentProps>(
  ({ arabic }) => `
  text-align:${arabic ? "right" : "left"};
  direction: ${arabic ? "rtl" : "ltr"};
  font-family: ${arabic ? "Cairo" : "Dm Sans"};
  border-radius: 200px;
  border:none;
  width:392px !important;
height: 50px;
padding:0 20px;
background: rgba(217, 217, 217, 0.30);
  ::placeholder {
    font-family: ${arabic ? "Cairo" : "Dm Sans"};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    direction:rtl;
    color: #99a5b4;
  }
  
        `
);

export type QuestionTextProps = {
  arabic?: boolean;
};
export const QuestionText = styled.div<QuestionTextProps>(
  ({ arabic }) => `
  font-family:${arabic ? "Cairo" : "Dm Sans"};
  font-style: normal;
  font-weight: 700;
  animation: 3s infinite alternate slidein;
  font-size: 18px;
  line-height: 36px;
  direction: ${arabic ? "rtl" : "ltr"};
  text-align: center;
  color: #000000;
  margin-bottom: 24px;
}
`
);

export type OptionBtnProps = {
  justifyContent?: string;
  marginTop?: string;
  arabic?: boolean;
  width?: string;
  selected?: boolean;
};
export const OptionBtn = styled.div<OptionBtnProps>(
  ({ justifyContent, marginTop, arabic, width, selected }) => `
    display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  gap: 4px;
  min-width: ${width ? width : "140px"};
  background: #E8E9F5B2;
  border-radius: 61px;
  margin: 5px 20px 0 0;
  cursor:pointer;
font-family: ${arabic ? "Cairo" : "Dm Sans"};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  
  line-height: 20px;
  text-align: center;
  
    
    @media (max-width: 992px) {
      width: ${width ? width : "100%"} ;
      &:hover {

      }
    }
`
);

export const SubjectCon = styled.div`
  background: #e8e9f5b2;
  color: #191d23;
  border-radius: 40px;
  padding: 4px;
  margin-top: 10px;
  width: max-content;
  texttransform: capitalize;
  padding-left: 15px;
  margin-right: 10px;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
`;

export type LabelProps = {
  arabic?: boolean;
};
export const Label = styled.span<LabelProps>(
  ({ arabic }) => `
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 4px;
  text-align:${arabic ? "right" : "left"};
  direction: ${arabic ? "rtl" : "ltr"};
  
        `
);

export const FormContainer = styled.div`
  width: 384px;
  display: flex;
  justify-content: center;
  @media (max-width: 992px) {
    width: 100%;
  }
`;
