import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
@font-face {
    font-family: "arabic";
    src: url("../../fonts/arabic/arabic.ttf");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}


@font-face {
    font-family: "arabic";
    src: url("../../fonts/arabic/arabic.ttf");
    font-style: medium;
    font-weight: 500;
    font-display: swap;
}
.sticky-top{
  z-index: 99;

}
.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.custom-dd-menu{
  padding: 5px;
  cursor: pointer;
  padding-left:15px;
}
  .res-con-width{
  width:392px !important;
  }
.custom-dd-menu:hover{
  color:#FD3E60;
  background:#ff04321c;
}
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};

    transition: all 0.50s linear;
    height:100%;
  }
  .pc-view{
    display:block;
  }
  .mobile-view{
    display:none !important;
  }
  .country-dd{
    border: 1px solid #A0ABBB;
    border-radius: 4px;
    outline: none;
    padding: 16px 12px;
    width: 384px;
    height: 56px;
    background: #FFFFFF;
  }
  .search-icon {
    position: absolute;
    padding: 8px 14px;
    top:6px
  }
  .search-input {
    width: 392px !important;
    border: 1px solid #d7dfe9 !important;
    border-radius: 4px !important;
    padding:0 15px;
    font-family: Cairo;
    font-style: normal;
    font-weight: normal;
    height: 56px !important;
    font-size: 16px;
    color: #3f4247;
  }
  .search-input::placeholder {
    font-family: Cairo;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    direction:rtl;
    color: #99a5b4;
  }
  
  .form-input{
    width: 392px !important;
    border: 1px solid #d7dfe9 !important;
    border-radius: 4px !important;
    font-family: Cairo;
    font-style: normal;
    font-weight: normal;
    height: 56px !important;
    font-size: 16px;
    color: #3f4247;
    padding:0 15px;
  }

// .PhoneInputInput {
//   border: none;
//   outline: none;
// }
// .PhoneInput {
//   border: 1px solid #191d23;
//   border-radius: 3px;
//   padding: 7px;
//   height: 56px;
// }
.bg-light{
  box-shadow: 0px 4px 16px rgb(166 171 180 / 16%);

}
.PhoneInputCountryIcon--border{
  background-color: transparent !important;
  box-shadow: none !important
}

.phoneInput-en .PhoneInputInput{
  border: 1px solid #d7dfe9;
  padding: 7px;
   border-radius: 200px !important;
  background: rgba(217, 217, 217, 0.3);
  height: 56px;
  text-align: left;
  direction: ltr;
}
.phoneInput-ar .PhoneInputInput{
  border: 1px solid #d7dfe9;
   border-radius: 200px !important;
  background: rgba(217, 217, 217, 0.3);
  padding: 7px;
  height: 56px;
  text-align: right;
  direction:ltr;
 
}
.PhoneInputInput{
  border: 1px solid #d7dfe9;
  border-radius: 3px;
  padding: 7px;
  height: 56px;
 
}

.PhoneInputCountry{
  border: 1px solid #d7dfe9;
 border-radius: 200px !important;
  background: rgba(217, 217, 217, 0.3);
  padding: 7px;
  height: 56px;
  width: 88px;
  margin: 0 var(--PhoneInputCountrySelect-marginRight) 0 0;
}
.PhoneInputCountryIcon{
  width: 61px !important;
  height: 25px !important;
}
.navbar-brand{
  margin:0px !important;
}
  input:focus,
  input.form-control:focus,
  textarea.form-control:focus,form-input-custom:focus {
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    border: 1px solid #FD3E60 !important;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }
  .slider-uni-logo{
    width: 100px;
    height: 53px;
    position:absolute;
    top:0px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }
  .nav-link:hover{
    color: #fd3e60;

  }
  .slick-slide{
    // width: 324px !important;
    padding: 0 0 0 15px;
  }
  .slick-list{
    width:80%;
  }
  .slick-slider{
    display:flex !important;
    justify-content:center;
  }
  .accordion-item{
    border:none !important;
    border-bottom: 1px solid #A5A9AC !important;

  }
  .accordion-button:focus{
    border-color: none !important;
    outline: 0;
    box-shadow: none !important;
  }
  .slick-dots{
    bottom: -40px !important;

  }
  .accordion-btn-en .accordion-button{
    direction:ltr;
    font-family: Dm Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: left;
    color: #000000;
  }
  .accordion-btn-ar .accordion-button{
    direction:rtl;
    font-family: Cairo;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #000000;
  }
  .faq-text{
  }

 
  .accordion-btn-en .accordion-button::after{
    background-image: url('https://storage.googleapis.com/uapply/PlusIcon.png');
    font-size: 24px;
    margin-right: 0;
    margin-left: auto;
  }

  .accordion-btn-ar .accordion-button::after{
    background-image: url('https://storage.googleapis.com/uapply/PlusIcon.png');
    font-size: 24px;
    margin-right: auto;
    margin-left:0;
  }
  .accordion-button:not(.collapsed)::after{
    content: "➖" !important;
    background-image:none;
    font-size: 18px;
    color:#FD3E60;
  }


  .succcess-animation{
    width:60%;
    height:90vh;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index:1;
    text-align: center;
  }
  .fade-in-bottom{
    animation: fadeInDown 1s;

    @-webkit-keyframes fadeInDown {
      from {
          opacity:0;
          -webkit-transform: translatey(-50px);
          -moz-transform: translatey(-50px);
          -o-transform: translatey(-50px);
          transform: translatey(-50px);
      }
      to {
          opacity:1;
          -webkit-transform: translatey(0);
          -moz-transform: translatey(0);
          -o-transform: translatey(0);
          transform: translatey(0);
      }
  }
  @-moz-keyframes fadeInDown {
      from {
          opacity:0;
          -webkit-transform: translatey(-50px);
          -moz-transform: translatey(-50px);
          -o-transform: translatey(-50px);
          transform: translatey(-50px);
      }
      to {
          opacity:1;
          -webkit-transform: translatey(0);
          -moz-transform: translatey(0);
          -o-transform: translatey(0);
          transform: translatey(0);
      }
  }
  @keyframes fadeInDown {
      from {
          opacity:0;
          -webkit-transform: translatey(-50px);
          -moz-transform: translatey(-50px);
          -o-transform: translatey(-50px);
          transform: translatey(-50px);
      }
      to {
          opacity:1;
          -webkit-transform: translatey(0);
          -moz-transform: translatey(0);
          -o-transform: translatey(0);
          transform: translatey(0);
      }
  }
  
  }
  .input-cross{
    position: absolute;
    top: 11px;
    font-family: Cairo;
    font-style: normal;
    font-weight: normal;
    height: 56px !important;
    font-size: 16px;
    color: #3f4247;
    cursor:pointer
  }
  button:focus:not(:focus-visible){
    outline: 0 !important;
  }
  .accordion-button:not(.collapsed){
    color:  #FD3E60;
    
    background-color: #fff;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
  }
  .progress {
    width: 60%;
    background-color: rgba(205, 205, 205, 0.3);
    border-radius: 8px;
    height: 16px;
}
.progress-bar {
    background-color: #FD3E60;
}
html, body {
   background:#fff;
   height:auto;
   }
@media (max-width: 1300px) {
.index-banner-img{
    width:900px;
    height:900px;
    display:block;
  }
}

@media (max-height: 850px) {
  .index-banner-img{
    width: 744px;
    height: 740px;
    }
  }
  
  
  @media (max-width: 992px) {
    body { 
      background:#fff; 
      height:auto;
      overflow: scroll !important;
    }
       .res-con-width{
  width:100% !important;
  }
    body.modal-open {
      height: 100vh !important;
      overflow-y: hidden !important;
      position: fixed !important;
    }
    .succcess-animation{
      width:100%;
      height:100vh;
    }
    .slick-list{
      width:100%;
    }
    .container{
      padding: 0 20px !important;
    }
    .pc-view{
      display:none !important;
    }
    .arrow-slider{
      width: 30px;
    }
    .progress {
      width: 75%;
      height:10px;
    }
   
    .accordion-button{
      font-size:12px;
    }
    .mobile-view{
      display: block !important;
    }
    .form-input{
      width: 100% !important;
    }
    .search-input {
      width: 100% !important;
    }
    .country-dd{
      width: 100%;
    }
    .res-w-100{
      width: 100%;
    }

    .res-home-btn{
      width:80% !important;
    }
  }
  `;
