import React from "react";

type Props = {
  width?: string;
  height?: string;
  onClick?: any;
  color?: any;
};

export const CloseIcon = (props: Props) => {
  return (
    <svg
      width={props.width ? props.width : 14}
      height={props.height ? props.height : 14}
      viewBox="0 0 14 14"
      fill="none"
      style={{ cursor: "pointer" }}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.43 2.48299L6.9 5.01099L4.37 2.48099C4.23671 2.34217 4.07704 2.23136 3.90037 2.15504C3.7237 2.07873 3.53357 2.03845 3.34113 2.03657C3.14868 2.03469 2.9578 2.07124 2.77967 2.14408C2.60154 2.21692 2.43974 2.32459 2.30376 2.46077C2.16777 2.59696 2.06034 2.75891 1.98776 2.93715C1.91518 3.11539 1.87891 3.30633 1.88107 3.49877C1.88324 3.6912 1.9238 3.88127 2.00037 4.05784C2.07694 4.2344 2.18799 4.3939 2.327 4.52699L4.856 7.05699L2.329 9.58399C2.06481 9.85695 1.91846 10.2228 1.92152 10.6027C1.92457 10.9825 2.07678 11.346 2.34533 11.6147C2.61388 11.8833 2.97726 12.0357 3.35712 12.039C3.73698 12.0422 4.10291 11.896 4.376 11.632L6.9 9.09999L9.428 11.629C9.5614 11.7675 9.72108 11.8781 9.8977 11.9542C10.0743 12.0303 10.2644 12.0705 10.4567 12.0723C10.649 12.0741 10.8397 12.0376 11.0178 11.9648C11.1958 11.8921 11.3576 11.7845 11.4936 11.6485C11.6296 11.5126 11.7371 11.3508 11.8098 11.1728C11.8826 10.9947 11.9191 10.804 11.9173 10.6117C11.9155 10.4193 11.8753 10.2293 11.7992 10.0527C11.7231 9.87606 11.6125 9.71638 11.474 9.58299L8.95 7.05799L11.477 4.53099C11.7412 4.25802 11.8875 3.89217 11.8845 3.5123C11.8814 3.13244 11.7292 2.76899 11.4607 2.50031C11.1921 2.23163 10.8287 2.07924 10.4489 2.076C10.069 2.07276 9.70309 2.21893 9.43 2.48299Z"
        fill={props.color ? props.color : "#444A58"}
      />
    </svg>
  );
};
