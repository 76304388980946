// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export type HeaderTextProps = {
  marginBottom?: string;
  arabic?: boolean;
  width?: string;
  textAlign?: string;
};
export const HeaderText = styled.div<HeaderTextProps>(
  ({ marginBottom, arabic, width, textAlign }) => `
  font-family: ${arabic ? "Cairo" : "Dm Sans"};
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 65px;
  text-align: center;
  margin-bottom:${marginBottom};
  letter-spacing: -1.87579px;
  color: #1e2432;
  direction: ${arabic ? "rtl" : "ltr"};
  @media (max-width: 992px) {
    height: auto;
    font-size: 34px;
    width: ${width ? width : "auto"};
    margin-bottom:${marginBottom ? "34px" : 0};
    text-align: ${textAlign ? textAlign : "center"};
  }
      `
);

export const ContactHeaderText = styled.div<HeaderTextProps>(
  ({ arabic }) => `
  font-family: ${arabic ? "Cairo" : "Dm Sans"};
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 65px;
  text-align: center;
  color: #fff;
  direction: ${arabic ? "rtl" : "ltr"};
  @media (max-width: 992px) {
    font-size: 22px;
    line-height: 44px;
    text-align: center;
  }
      `
);

export type SubtextProps = {
  fontSize?: string;
  textAlign?: string;
  lineHeight?: string;
  fontWeight?: number;
  direction?: String;
  arabic?: boolean;
  responsive?: boolean;
};
export const Subtext = styled.div<SubtextProps>(
  ({
    fontSize,
    textAlign,
    lineHeight,
    fontWeight,
    direction,
    arabic,
    responsive,
  }) => `
    font-family: ${arabic ? "Cairo" : "Dm Sans"};
    font-style: normal;
    font-weight: ${arabic ? 600 : 500};
    font-size: ${fontSize};
    line-height: ${lineHeight ? lineHeight : "20px"};
    text-align: ${textAlign};
    color: #333333;
    direction: ${direction};
    @media (max-width: 992px) {
      font-size: ${responsive ? "20px" : ""};
      // line-height: ${responsive ? "36px" : "44px"};

  }
      `
);

export type RequiredDocTextProps = {
  arabic?: boolean;
};
export const RequiredDocText = styled.div<RequiredDocTextProps>(
  ({ arabic }) => `
    font-family: ${arabic ? "Cairo" : "Dm Sans"};
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 36px;
    text-align: center;
    color: #333333;
    direction: ${arabic ? "rtl" : "ltr"};
  @media (max-width: 992px) {
    font-size: 22px;
    line-height: 43px;

  }
      `
);

export type RequiredDocSubtextProps = {
  arabic?: boolean;
};
export const RequiredDocSubtext = styled.div<RequiredDocSubtextProps>(
  ({ arabic }) => `
    font-family: ${arabic ? "Cairo" : "Dm Sans"};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    color: #333333;
    direction: ${arabic ? "rtl" : "ltr"};
  @media (max-width: 992px) {
    font-weight: 500;
    font-size: 18px;
    line-height: 33px;
  }
      `
);

export type PartnerSubtextProps = {
  arabic?: boolean;
};
export const PartnerSubtext = styled.div<PartnerSubtextProps>(
  ({ arabic }) => `
    font-family: ${arabic ? "Cairo" : "Dm Sans"};
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 36px;
    text-align: center;
    color: #676767;
    direction: ${arabic ? "rtl" : "ltr"};
  @media (max-width: 992px) {
    font-size: 22px;
    line-height: 36px;
    text-align: ${arabic ? "right" : "left"};
  }
      `
);

export type ContactSubtextProps = {
  arabic?: boolean;
};
export const ContactSubtext = styled.div<ContactSubtextProps>(
  ({ arabic }) => `
    font-family: ${arabic ? "Cairo" : "Dm Sans"};
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 44px;
    text-align: center;
    color: #fff;
    direction: ${arabic ? "rtl" : "ltr"};
    @media (max-width: 992px) {
      font-size: 18px;
      line-height: 30px;
  }
      `
);

export type ContactButtonProps = {
  disableBtnStyle?: boolean;
  btnType?: string;
  width?: string;
  loading?: number;
};

export const ContactButton = styled.button<ContactButtonProps>(
  ({ disableBtnStyle, btnType, width, loading }) => `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  width: ${width};
  height: 43.94px;
  transition: all 0.3s linear;
  background: ${
    loading
      ? "#fff"
      : disableBtnStyle
      ? "#A5A9AC66"
      : btnType === "dark"
      ? "#FD3E60"
      : "#fff"
  };
  border:  ${
    disableBtnStyle
      ? "none"
      : `1px solid
    ${btnType === "dark" ? "#FD3E60" : "#1E2432"}`
  };
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-transform: capitalize;
  color:   ${
    disableBtnStyle ? "#A0ABBB" : btnType === "light" ? "#1D2939" : "#fff"
  };
  @media (max-width: 992px) {
    width: 100% !important;
  }

  
        `
);

export type ResultCardTextProps = {
  arabic?: boolean;
};
export const ResultCardText = styled.div<ResultCardTextProps>(
  ({ arabic }) => `
      font-family: ${arabic ? "Cairo" : "Dm Sans"};
      direction: ${arabic ? "rtl" : "ltr"}
      font-style: normal;
      font-weight: ${arabic ? 600 : 500};
      font-size: 14px;
      line-height: 28px;  
      text-align: ${arabic ? "right" : "left"};
      color: #000000;
    @media (max-width: 992px) {
    font-size: 16px;
    line-height: 26px;


  }
      `
);

export const ErrorMessage = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #ef4444;
`;
export const ContainerArcks = styled.div`
  background-size: cover;
  height: 88.49px;
  background-image: url("https://storage.googleapis.com/uapply/footer-arck.png");
`;
export const SectionMargin = styled.div`
  margin-top: 200px;
  @media (max-width: 992px) {
    margin-top: 62px;
  }
`;

export const ResultCardSectionMargin = styled.div`
  margin-top: 91px;
  @media (max-width: 992px) {
    margin-top: 70px;
  }
`;

export const ResultCardHeaderSection = styled.div`
  margin-top: 68px;
  @media (max-width: 992px) {
    margin-top: 30px;
  }
`;

export const AnimationSection = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  // z-index: 9;
  @media (max-width: 992px) {
    margin-top: 30px;
  }
`;

export const ResultCard = styled.div`
  background: #ffffff;
  position: relative;
  border: 1px solid #299cf7;
  border-radius: 8px;
  padding: 24px;
  width: 327px;
  z-index: 1;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const ResultCardTop = styled.div`
  position: absolute;
  width: 326px;
  top: -35px;
  height: 38px;
  background: #fd3e60;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  left: 0;
  z-index: 9;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

export type TextProps = {
  arabic?: boolean;
  textAlign?: string;
};
export const Text = styled.div<TextProps>(
  ({ arabic, textAlign }) => `
  font-family: ${arabic ? "Cairo" : "Dm Sans"};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: ${textAlign} !important;
  color: #676767;
  direction: ${arabic ? "rtl" : "ltr"};
  text-align: right;
  opacity: 0.7;
  margin-top:10px;

  @media (max-width: 992px) {
    width: 100%;
  }
`
);

export type RowColProps = {
  direction?: string;
  pcDirection?: string;
};
export const RowCol = styled.div<RowColProps>(
  ({ direction, pcDirection }) => `
  display: flex;
  flex-direction: ${pcDirection};
  @media (max-width: 992px) {
    flex-direction: ${direction ? direction : "column"};
    align-items:center;
  }
  
`
);

export type ImgContainerProps = {
  background?: string;
  last?: boolean;
  width?: string;
  height?: string;
};
export const ImgContainer = styled.div<ImgContainerProps>(
  ({ background, last, width, height }) => `
  background: ${background};
  height: ${height};
  margin-bottom:${last ? "0px" : "80px"};
  width: ${width};
  background-position: 50% !important;
  background-size: cover !important;
  @media (max-width: 1200px) {
    width: 100%;
    height: 460px;
  }
  @media (max-width: 992px) {
    width: 100%;
    height: 260px;
  }
  @media (max-width: 768px) {  
    width: 278px;
    height: 220px;

      }
`
);

// export type ImgContainerProps = {
//   background?: string;
//   last?: boolean;
//   width?: string;
//   height?: string;
// };
// export const ImgContainer = styled.div<ImgContainerProps>(
//   ({ background, last, width, height }) => `
//   background: ${background};
//   height: ${height};
//   margin-bottom:${last ? "0px" : "80px"};
//   width: ${width};
//   background-position: 50% !important;
//   background-size: cover !important;
//   @media (max-width: 1200px) {
//     width: 100%;
//     height: 460px;
//   }
//   @media (max-width: 992px) {
//     height: 260px;
//   }
//   @media (max-width: 768px) {
//     width: 256px;
//     height: 251px;
//   }
// `
// );
