/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Accordion, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { ChatIcon, CircleCheck, Logo } from "../Assets";
import {
  ButtonComponent,
  FaqComponent,
  SuccessAnimation,
  UniversitySlider,
} from "../Components";
import { dataAction, State } from "../state";
import {
  ContactHeaderText,
  ContainerArcks,
  HeaderText,
  ImgContainer,
  ResultCard,
  ResultCardSectionMargin,
  ResultCardText,
  ResultCardTop,
  RowCol,
  SectionMargin,
  Subtext,
  Text,
  ContactSubtext,
  RequiredDocText,
  RequiredDocSubtext,
  PartnerSubtext,
  ResultCardHeaderSection,
  AnimationSection,
  ContactButton,
} from "./styled/result";
import "./styled/result.scss";

interface IResultProps {}

export const Result: React.FunctionComponent<IResultProps> = (props) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  let { language } = useParams();
  // const [lang, setlang] = React.useState(language);

  const docRequired = [
    {
      Bachlors: [
        t("common:requiredDocBachlor1"),
        t("common:requiredDocBachlor2"),
        t("common:requiredDocBachlor3"),
      ],
    },
    {
      Masters: [
        t("common:requiredDocMaster1"),
        t("common:requiredDocMaster2"),
        t("common:requiredDocMaster3"),
        t("common:requiredDocMaster4"),
        t("common:requiredDocMaster5"),
        t("common:requiredDocMaster6"),
      ],
    },
    {
      phd: [
        t("common:requiredDocPhd1"),
        t("common:requiredDocPhd2"),
        t("common:requiredDocPhd3"),
        t("common:requiredDocPhd4"),
        t("common:requiredDocPhd5"),
        t("common:requiredDocPhd6"),
        t("common:requiredDocPhd7"),
        t("common:requiredDocPhd8"),
        t("common:requiredDocPhd9"),
      ],
    },
  ];

  const dispatch = useDispatch();
  const { showAnimation } = bindActionCreators(dataAction, dispatch);
  const data = useSelector((state: State) => state.data);

  React.useEffect(() => {
    window.scroll(0, 0);
    // setlang(language);
    i18n.changeLanguage(language);
    document.body.style.overflow = "scroll";
    document.body.style.height = "auto";
    // showAnimation(true);
  }, []);

  if (!location.state) {
    return <Navigate to="/" />;
  }

  if (!language) {
    return <Navigate to="/ar/results" />;
  }

  localStorage.setItem("degree", location.state.degree.en_name);

  var degree = localStorage.getItem("degree");
  var qualified = location.state.qualified;

  return (
    <>
      {data.show ? (
        <AnimationSection>
          <SuccessAnimation
            onComplete={async () => {
              showAnimation(false);
            }}
          />
        </AnimationSection>
      ) : null}
      <Container>
        <ResultCardHeaderSection>
          <HeaderText arabic={i18n.language === "ar"}>
            {t("common:applicationSubmitted")}
          </HeaderText>
          <Subtext
            arabic={i18n.language === "ar"}
            fontSize="16px"
            textAlign="center"
          >
            {t("common:applicationSubmittedSubtext")}
          </Subtext>
        </ResultCardHeaderSection>
        <ResultCardSectionMargin className="d-flex justify-content-center">
          <ResultCard>
            <ResultCardTop className="d-flex justify-content-center align-items-center">
              <Logo color="#fff" style={{ width: "55px", height: 17 }} />
            </ResultCardTop>
            <div style={{}}>
              <RequiredDocText arabic={i18n.language === "ar"}>
                {t("common:requiredDoc")}
              </RequiredDocText>
              <RequiredDocSubtext arabic={i18n.language === "ar"}>
                {t("common:toPursue")}{" "}
                <span style={{ fontWeight: 900 }}>
                  {i18n.language === "ar"
                    ? location.state.degree.ar_name
                    : location.state.degree.en_name}
                </span>{" "}
                {t("common:degreeinUK")}
              </RequiredDocSubtext>
            </div>
            <div
              style={{
                borderTop: "1px solid #E8E9F5",
                borderBottom: "1px solid #E8E9F5",
                padding: "14px 0",
                margin: "9px 0  30px 0",
              }}
            >
              {degree === "Bachelor's"
                ? docRequired[0].Bachlors?.map((x: any) => (
                    <div
                      key={x}
                      className="d-flex flex-row align-items-start"
                      style={{
                        direction: i18n.language === "ar" ? "rtl" : "ltr",
                        marginBottom: 11,
                      }}
                    >
                      <div>
                        <CircleCheck
                          style={{
                            margin:
                              i18n.language === "en"
                                ? "0 10px 0 0"
                                : "0 0 0 10px",
                          }}
                        />
                      </div>
                      <ResultCardText arabic={i18n.language === "ar"}>
                        {x}
                      </ResultCardText>
                    </div>
                  ))
                : degree === "Master's"
                ? docRequired[1].Masters?.map((x: any) => (
                    <div
                      key={x}
                      className="d-flex flex-row align-items-start"
                      style={{
                        direction: i18n.language === "ar" ? "rtl" : "ltr",
                        marginBottom: 5,
                      }}
                    >
                      <div>
                        <CircleCheck
                          style={{
                            margin:
                              i18n.language === "en"
                                ? "0 10px 0 0"
                                : "0 0 0 10px",
                          }}
                        />
                      </div>
                      <ResultCardText arabic={i18n.language === "ar"}>
                        {x}
                      </ResultCardText>
                    </div>
                  ))
                : degree === "PhD"
                ? docRequired[2].phd?.map((x: any) => (
                    <div
                      key={x}
                      className="d-flex flex-row align-items-start"
                      style={{
                        direction: i18n.language === "ar" ? "rtl" : "ltr",
                        marginBottom: 5,
                      }}
                    >
                      <div>
                        <CircleCheck
                          style={{
                            margin:
                              i18n.language === "en"
                                ? "0 10px 0 0"
                                : "0 0 0 10px",
                          }}
                        />
                      </div>
                      <ResultCardText arabic={i18n.language === "ar"}>
                        {x}
                      </ResultCardText>
                    </div>
                  ))
                : null}
            </div>
            {qualified ? (
              <div
                className="d-flex justify-content-center"
                style={{ zIndex: 3 }}
              >
                <ButtonComponent
                  label={t("common:sendOnWa")}
                  btnType={"dark"}
                  width="460px"
                  style={{
                    height: 52,
                    fontSize: 16,
                    zIndex: 99,
                    fontFamily: i18n.language === "ar" ? "Cairo" : "Dm Sans",
                  }}
                  onClick={() => window.open("https://wa.me/447861104998")}
                />
              </div>
            ) : null}
          </ResultCard>
        </ResultCardSectionMargin>
        <SectionMargin>
          <HeaderText arabic={i18n.language === "ar"} marginBottom="86px">
            {t("common:services")}
          </HeaderText>

          <RowCol
            pcDirection={i18n.language === "ar" ? "row" : "row-reverse"}
            direction={"column-reverse"}
          >
            <Col md={6} style={{ padding: 0 }}>
              <div className="d-flex justify-content-center">
                <ImgContainer
                  width="548px"
                  height="470px"
                  background={`url("https://cdn.ulearn.study/courses/Screen%20Shot%202022-03-22%20at%202.54dd%20(1)-min.png")`}
                />
              </div>
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-center flex-column"
              style={{ padding: 0 }}
            >
              <Subtext
                fontSize="44px"
                lineHeight={"64px"}
                arabic={i18n.language === "ar"}
                className="consaltation-text pc-view"
                direction={i18n.language === "ar" ? "rtl" : "ltr"}
              >
                <b>{t("common:serviceSection1Text1")}</b>
                <br />{" "}
                <b style={{ color: "#FD3E60" }}>
                  {t("common:serviceSection1Text2")}
                </b>{" "}
                {t("common:serviceSection1Text3")}
              </Subtext>
              <Subtext
                fontSize="24px"
                lineHeight={"40px"}
                arabic={i18n.language === "ar"}
                className="mobile-view"
                direction={i18n.language === "ar" ? "rtl" : "ltr"}
              >
                <b>{t("common:serviceSection1Text1")}</b>{" "}
                <b style={{ color: "#FD3E60" }}>
                  {t("common:serviceSection1Text2")}
                </b>{" "}
                {t("common:serviceSection1Text3")}
              </Subtext>
              <Text
                arabic={i18n.language === "ar"}
                textAlign={i18n.language === "ar" ? "right" : "left"}
                className="consaltation-subtext"
              >
                {t("common:serviceSection1Desc")}
                <br />
                {i18n.language === "ar"
                  ? t("common:serviceSection1Desc2")
                  : null}
              </Text>
              <div
                style={{
                  marginBottom: 30,
                }}
                className="w-100 d-flex justify-content-end"
              ></div>
            </Col>
          </RowCol>

          <RowCol
            direction="column"
            pcDirection={i18n.language === "ar" ? "row" : "row-reverse"}
          >
            <Col
              md={6}
              className="d-flex justify-content-center flex-column"
              style={{ padding: 0 }}
            >
              <Subtext
                fontSize="44px"
                lineHeight={"64px"}
                arabic={i18n.language === "ar"}
                className="consaltation-text pc-view"
                direction={i18n.language === "ar" ? "rtl" : "ltr"}
              >
                <b>{t("common:serviceSection2Text1")}</b>
                <br />{" "}
                <b style={{ color: "#FD3E60" }}>
                  {t("common:serviceSection2Text2")}
                </b>
              </Subtext>
              <Subtext
                fontSize="24px"
                lineHeight={"44px"}
                arabic={i18n.language === "ar"}
                className="mobile-view"
                direction={i18n.language === "ar" ? "rtl" : "ltr"}
              >
                <b> {t("common:serviceSection2Text1")}</b>
                <b style={{ color: "#FD3E60" }}>
                  {" "}
                  {t("common:serviceSection2Text2")}
                </b>
              </Subtext>
              <Text
                arabic={i18n.language === "ar"}
                textAlign={i18n.language === "ar" ? "right" : "left"}
                className="consaltation-subtext"
              >
                {t("common:serviceSection2Desc")}
                <br />
                {t("common:serviceSection2Desc2")}
              </Text>
              <div
                style={{
                  marginBottom: 30,
                }}
                className="w-100 d-flex justify-content-end"
              ></div>
            </Col>
            <Col md={6} style={{ padding: 0 }}>
              <div className="d-flex justify-content-center">
                <ImgContainer
                  width="560px"
                  height="520px"
                  background={`url("https://cdn.ulearn.study/courses/Screen%20Shot%202022-03-22%20at%202.54-min.png")`}
                />
              </div>
            </Col>
          </RowCol>

          <RowCol
            pcDirection={i18n.language === "ar" ? "row" : "row-reverse"}
            direction="column-reverse"
          >
            <Col md={6} style={{ padding: 0 }}>
              <div className="d-flex justify-content-center">
                <ImgContainer
                  last
                  width="548px"
                  height="428px"
                  background={`url("https://cdn.ulearn.study/courses/Screen%20Shot%202022-03-22%20at%202.54%20(1)-min.png")`}
                />
              </div>
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-center flex-column"
              style={{ padding: 0 }}
            >
              <Subtext
                fontSize="44px"
                arabic={i18n.language === "ar"}
                lineHeight={"64px"}
                className="consaltation-text pc-view"
                direction={i18n.language === "ar" ? "rtl" : "ltr"}
              >
                {t("common:serviceSection3Text1")}
                <br />{" "}
                <b style={{ color: "#FD3E60" }}>
                  {" "}
                  {t("common:serviceSection3Text2")}
                </b>
              </Subtext>
              <Subtext
                fontSize="24px"
                lineHeight={"44px"}
                className="mobile-view"
                arabic={i18n.language === "ar"}
                direction={i18n.language === "ar" ? "rtl" : "ltr"}
              >
                <b style={{ color: "#FD3E60" }}>
                  {t("common:serviceSection3Text1")}
                </b>{" "}
                <b>{t("common:serviceSection3Text2")}</b>
              </Subtext>
              <Text
                arabic={i18n.language === "ar"}
                textAlign={i18n.language === "ar" ? "right" : "left"}
                className="consaltation-subtext"
              >
                {t("common:serviceSection3Desc")}
                <br />
                {t("common:serviceSection3Desc2")}
              </Text>
              <div
                style={{
                  marginBottom: 30,
                }}
                className="w-100 d-flex justify-content-end"
              ></div>
            </Col>
          </RowCol>
        </SectionMargin>
      </Container>
      {qualified ? (
        <>
          <SectionMargin className="container pc-view">
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{
                background: "#fd3e60",
                padding: "40px 20px",
                borderRadius: 20,
              }}
            >
              <ChatIcon style={{ marginBottom: 24 }} />
              <ContactHeaderText arabic={i18n.language === "ar"}>
                {t("common:contactSection")}
              </ContactHeaderText>
              <ContactSubtext arabic={i18n.language === "ar"}>
                {t("common:contactSectionDesc")}{" "}
              </ContactSubtext>
              <ContactButton
                btnType={"light"}
                width="335px"
                style={{
                  marginTop: 24,
                  height: 56,
                  border: "none",
                }}
                onClick={() => window.open("https://wa.me/447861104998")}
              >
                {t("common:contactBtn")}
              </ContactButton>
            </div>
          </SectionMargin>
          <SectionMargin className="mobile-view">
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{
                background: "#fd3e60",
                padding: "40px 20px",
                borderRadius: 20,
              }}
            >
              <ChatIcon style={{ marginBottom: 24 }} />
              <ContactHeaderText arabic={i18n.language === "ar"}>
                {t("common:contactSection")}
              </ContactHeaderText>
              <ContactSubtext arabic={i18n.language === "ar"}>
                {t("common:contactSectionDesc")}{" "}
              </ContactSubtext>
              <ContactButton
                btnType={"light"}
                width="335px"
                style={{
                  marginTop: 24,
                  height: 56,
                  border: "none",
                }}
                onClick={() => window.open("https://wa.me/447861104998")}
              >
                {t("common:contactBtn")}
              </ContactButton>
            </div>
          </SectionMargin>
        </>
      ) : null}
      <Container>
        <SectionMargin className="d-flex flex-column align-items-center justify-content-center">
          <HeaderText
            width="100%"
            textAlign={i18n.language === "ar" ? "right" : "left"}
            arabic={i18n.language === "ar"}
          >
            {t("common:partnerSection")}{" "}
          </HeaderText>
          <PartnerSubtext arabic={i18n.language === "ar"}>
            {t("common:partnerSectionDesc")}
          </PartnerSubtext>
        </SectionMargin>
      </Container>
      <div className="container pc-view" style={{ marginTop: 50 }}>
        <UniversitySlider arabic={i18n.language === "ar"} />
      </div>
      <div className="mobile-view" style={{ marginTop: 40 }}>
        <UniversitySlider arabic={i18n.language === "ar"} />
      </div>
      <Container>
        {" "}
        <SectionMargin>
          <HeaderText
            textAlign={i18n.language === "ar" ? "right" : "left"}
            arabic={i18n.language === "ar"}
          >
            {t("common:faq")}
          </HeaderText>
          <Accordion>
            <FaqComponent
              answer={t("common:faqAnswer1")}
              question={t("common:faqQuestion1")}
              customPadding={""}
              eventKeyProps={"0"}
              arabic={i18n.language === "ar"}
            />
            <FaqComponent
              answer={t("common:faqAnswer2")}
              question={t("common:faqQuestion2")}
              customPadding={""}
              eventKeyProps={"1"}
              arabic={i18n.language === "ar"}
            />
            <FaqComponent
              answer={t("common:faqAnswer3")}
              question={t("common:faqQuestion3")}
              customPadding={""}
              eventKeyProps={"2"}
              arabic={i18n.language === "ar"}
            />
            <FaqComponent
              answer={t("common:faqAnswer4")}
              question={t("common:faqQuestion4")}
              customPadding={""}
              arabic={i18n.language === "ar"}
              eventKeyProps={"3"}
            />
            <FaqComponent
              answer={t("common:faqAnswer5")}
              question={t("common:faqQuestion5")}
              customPadding={""}
              eventKeyProps={"4"}
              arabic={i18n.language === "ar"}
            />
          </Accordion>
        </SectionMargin>
        <SectionMargin></SectionMargin>
      </Container>
      <ContainerArcks />
    </>
  );
};
