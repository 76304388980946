import * as React from "react";
import { CloseIcon } from "../../Assets";
import {
  BadgeCon,
  BadgeText,
  InputComponent,
  SubjectsContainer,
  Text,
} from "./styled";

interface IUniversityInputComponentProps {
  selectedUniversity: any;
  setselectedUniversity: any;
  setsearchText: any;
  arabic: boolean;
  universities: Array<any>;
  searchText: any;
  searchUniversity: any;
  t: Function;
  setuniversities: any;
  seterrorMessage: Function;
  error: any;
  loading: any;
  university: any;
  onClickNext?: any; // renamed from recommendedUniversities
}

export const UniversityInputComponent: React.FunctionComponent<
  IUniversityInputComponentProps
> = (props) => {
  const {
    selectedUniversity,
    setselectedUniversity,
    setsearchText,
    arabic,
    universities,
    searchUniversity,
    searchText,
    setuniversities,
    t,
    seterrorMessage,
    error,
    loading,
    university,
    onClickNext, // now includes recommended universities
  } = props;

  const [closeMenu, setcloseMenu] = React.useState(false);
  const dropdownRef = React.useRef<HTMLDivElement | null>(null);

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    seterrorMessage("");
    setsearchText(e.target.value);
    setcloseMenu(true);
    await searchUniversity(e);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setcloseMenu(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectUniversity = (university: any) => {
    if (
      selectedUniversity.length < 5 &&
      !selectedUniversity.some((item: any) => item.id === university.id) // Ensure no duplicate
    ) {
      setselectedUniversity([...selectedUniversity, university]);
      setsearchText(""); // Clear input when a university is selected
      setuniversities([]); // Clear universities after selection
      setcloseMenu(false); // Close dropdown
      seterrorMessage("");
    } else if (selectedUniversity.length === 5) {
      seterrorMessage(t("common:universityError"));
    }
  };

  return (
    <div className="res-w-100">
      <div className="d-flex w-100 justify-content-center">
        <div
          className="d-flex res-w-100 flex-row"
          style={{ position: "relative" }}
          ref={dropdownRef}
        >
          <InputComponent
            placeholder={`${
              selectedUniversity.length
                ? t("common:universityAddmorePlaceholder")
                : t("common:universityPlaceholder")
            }`}
            name="name"
            onChange={handleSearch}
            type="text"
            autoComplete="off"
            value={searchText}
            arabic={arabic}
            onClick={() => setcloseMenu(true)}
          />

          {closeMenu && (
            <div
              className="d-flex flex-column"
              style={{
                background: "#fff",
                position: "absolute",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                borderRadius: 8,
                top: 60,
                width: "100%",
                padding: "4px 0",
              }}
            >
              {loading ? (
                <div
                  className="d-flex justify-content-center"
                  style={{ color: "#969696" }}
                >
                  Loading...
                </div>
              ) : (
                // Filter out selected universities to prevent duplicates
                universities
                  .filter(
                    (uni) =>
                      !selectedUniversity.some(
                        (selected: any) => selected.id === uni.id
                      )
                  )
                  .map((a: { id: string; slug: string; name: string }) => (
                    <Text
                      key={a.id}
                      onClick={() => handleSelectUniversity(a)}
                      className="custom-dd-menu"
                      style={{ padding: "5px 15px" }}
                      arabic={arabic}
                    >
                      {a.name}
                    </Text>
                  ))
              )}
            </div>
          )}
        </div>
      </div>
      {error}
      <SubjectsContainer className="d-flex flex-row flex-wrap">
        {selectedUniversity?.map((a: any) => (
          <BadgeCon
            key={a.id}
            className="bg-grey-100 text-sm font-medium py-3 px-6 rounded-full cursor-pointer"
            style={{ backgroundColor: "#FD3E60" }} // Background color for selected
          >
            <BadgeText arabic={arabic} style={{ color: "#fff" }}>
              {a.name.length > 30
                ? `${a.name.replace(/-/g, " ").substring(0, 30)}...`
                : a.name}
            </BadgeText>
            <CloseIcon
              color={"#fff"}
              onClick={() => {
                const deleted = selectedUniversity?.filter(
                  (z: any) => z.id !== a.id
                );
                setselectedUniversity(deleted);
                seterrorMessage("");
              }}
              width="14px"
              height="14px"
            />
          </BadgeCon>
        ))}

        {/* Render all recommended universities, filtering out already selected ones */}
        {university
          .filter(
            (uni: any) =>
              !selectedUniversity.some((item: any) => item.id === uni.id) // Filter out selected
          )
          .slice(0, 5 - selectedUniversity.length) // Only show the top 4 recommended universities
          .map((uni: any) => (
            <BadgeCon
              key={uni.id}
              onClick={() => handleSelectUniversity(uni)}
              className="bg-grey-100 text-sm font-medium py-3 px-6 rounded-full cursor-pointer"
              style={{
                backgroundColor: selectedUniversity.some(
                  (item: any) => item.id === uni.id
                )
                  ? "#FD3E60" // Highlight if selected
                  : "#e8e9f5b2", // Default background for unselected
              }}
            >
              <BadgeText arabic={arabic}>
                {uni.name.length > 30
                  ? `${uni.name.replace(/-/g, " ").substring(0, 30)}...`
                  : uni.name}
              </BadgeText>
            </BadgeCon>
          ))}

        {selectedUniversity.length ? null : (
          <BadgeCon
            onClick={() => onClickNext()}
            className="bg-grey-100 text-sm font-medium py-3 px-6 rounded-full cursor-pointer"
            // style={{
            //   backgroundColor:
            //     ? "#FD3E60" // Highlight if selected
            //     : "#eee", // Default background for unselected
            // }}
          >
            <BadgeText arabic={arabic}>{t(`common:notSure`)}</BadgeText>
          </BadgeCon>
        )}
      </SubjectsContainer>
    </div>
  );
};
